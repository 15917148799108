import React from 'react';
import styled from 'styled-components';

import { InputProps } from 'libs/forms';
import { RatingStar } from 'ui/atoms/rating-star/rating-star';
import { adaptive } from 'ui/styles/addaptive-provider';

const stars = Array(5).fill('');

export function RatingInput(
  props: InputProps<number> & { className?: string },
) {
  return (
    <Grid className={props.className}>
      {stars.map((_, index) => (
        <Star
          key={index}
          fill={props.value >= index + 1}
          onClick={() => props.onChange(index + 1)}
        />
      ))}
    </Grid>
  );
}

const Star = styled(RatingStar)`
  height: 56px;
  width: 56px;
  cursor: pointer;

  ${adaptive} {
    height: 48px;
    width: 48px;
  }
`;

const Grid = styled.div`
  display: flex;
  align-items: center;
`;
