import React from 'react';
import styled, { css } from 'styled-components';

interface Props<T extends { id: number }> {
  items: T[];
  value?: number;
  onSelect(value: number): void;
  renderItem(item: T): React.ReactNode;
}

export function ProfileSelector<T extends { id: number }>(props: Props<T>) {
  return (
    <List>
      {props.items.map(item => (
        <SelectAbleItem
          key={item.id.toString()}
          isActive={props.value === item.id}
          onClick={() => props.onSelect(item.id)}
        >
          {props.renderItem(item)}
        </SelectAbleItem>
      ))}
    </List>
  );
}

const SelectAbleItem = styled.div<{ isActive: boolean; onClick(): void }>`
  cursor: pointer;
  padding: 8px 20px;
  margin: 0 -20px;

  :hover {
    background-color: #f7f8ff;
  }

  ${props =>
    props.isActive &&
    css`
      background-color: #f7f8ff;
    `}
`;

const List = styled.div`
  display: grid;
`;
