import React from 'react';
import styled from 'styled-components';

// @ts-ignore
import Icon from './checkbox.svg';

export type CheckBoxProps = {
  children?: React.ReactNode;
  className?: string;
  onClick?: () => void;
  checked?: boolean;
  disabled?: boolean;
};

export function CheckBox(props: CheckBoxProps) {
  const { children, className, ...inputProps } = props;
  return (
    <CheckBoxRoot className={className}>
      <input type="checkbox" {...inputProps} checked={inputProps.value} />
      <Label>{children}</Label>
    </CheckBoxRoot>
  );
}

const Label = styled.div`
  font-size: 14px;
  line-height: 21px;
  color: #172549;
  display: flex;
  align-items: center;

  :before {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    border: 2px solid var(--baseInputBorderColor);
    border-radius: 4px;
    margin-right: 4px;
  }
`;

const CheckBoxRoot = styled.label`
  cursor: pointer;

  input[type='checkbox'] {
    display: block;
    width: 0;
    height: 0;
    opacity: 0;
    clip: rect(0, 0, 0, 0);
  }

  input[type='checkbox']:checked + ${Label}:before {
    border-color: var(--darkBlue);
    background: darkBlue url(${Icon}) no-repeat center center;
  }

  input:focus + ${Label}:before {
    border-color: var(--darkBlue);
  }

  :hover ${Label}:before {
    border-color: var(--darkBlue);
  }
`;
