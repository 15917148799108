import styled from 'styled-components';
import { Step as LibStep } from 'libs/react-stepper';

import { mediaPhone } from '../../../../styles/media';

export { StepContent, Stepper, useStepper } from 'libs/react-stepper';

export const Steps = styled.div`
  padding: 0 32px;

  display: grid;
  grid-template-columns: repeat(5, 1fr);

  font-size: 14px;
  line-height: 21px;
  color: #a1b3ce;
  margin-bottom: 24px;

  @media (max-width: 420px) {
    font-size: 12px;
    padding: 0 15px;
  }
`;

export const Step = styled(LibStep)`
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
  color: #a1b3ce;
  padding: 0 16px;
  word-break: normal;
  &.active,
  &.passed {
    background: #f7f8ff;
  }
  &.active {
    color: #172549;

    position: relative;
    :not(:last-child) {
      :after {
        content: '';
        position: absolute;
        top: 0;
        left: 100%;
        clip-path: polygon(0 0, 0% 100%, 100% 50%);
        height: 100%;
        width: 10px;
        background: #f7f8ff;
      }
    }
  }

  :first-child {
    border-radius: 8px 0 0 8px;
  }

  :last-child {
    border-radius: 0 8px 8px 0;
  }

  @media (max-width: 420px) {
    font-size: 12px;
    padding: 0 8px;
  }
`;
