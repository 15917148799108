import React from 'react';
import styled, { css } from 'styled-components';

interface Props<T> {
  options: Array<{ label: string; value: T }>;
  className?: string;
  value: T;
  onChange(newValue: T): void;
}

export function SwitchButtons<T>(props: Props<T>) {
  return (
    <Options>
      {props.options.map(option => (
        <Option
          key={option.label}
          isActive={option.value === props.value}
          onClick={() => props.onChange(option.value)}
        >
          {option.label}
        </Option>
      ))}
    </Options>
  );
}

const Option = styled.div<{ isActive: boolean }>`
  padding: 0 12px;
  display: inline-flex;
  align-items: center;
  height: 100%;
  cursor: pointer;
  font-size: 14px;
  line-height: 21px;
  word-break: keep-all;
  ${props =>
    props.isActive
      ? css`
          background-color: var(--baseBorderColor);
        `
      : css`
          :hover {
            color: var(--link-color);
          }
        `}
`;

const Options = styled.div`
  display: inline-flex;
  height: 40px;
  border-radius: 8px;
  overflow: hidden;
  border: 2px solid var(--baseBorderColor);
`;
