import React from 'react';
import { InputProps } from 'libs/forms';

import styled from 'styled-components';

import { SimpleSelector } from '../simple-selector/simple-selector';
import { UserAvatar } from '../../../users';

type Props = InputProps<unknown> & {
  options: Array<{
    id: number;
    name: string;
    avatar: string;
  }>;
  placeholder: string;
  isInvalid?: boolean;
};

function ProfileLine(props: any) {
  return (
    <LineRoot>
      <UserAvatar as={props.AvatarComponent} avatar={props.profile.avatar} />
      <Name> {props.profile.name}</Name>
    </LineRoot>
  );
}

export function ProfileSelector(props: Props) {
  const options = props.options.map(profile => ({
    value: profile.id,
    label: (
      <ProfileLine
        key={profile.id}
        profile={profile}
        AvatarComponent={props.AvatarComponent}
      />
    ),
  }));
  return (
    <SimpleSelector
      options={options}
      placeholder={props.placeholder}
      value={props.value}
      onChange={props.onChange}
      isInvalid={props.isInvalid}
    />
  );
}

const Name = styled.div`
  font-size: 14px;
  line-height: 1.5;
`;
const LineRoot = styled.div`
  display: flex;
  align-items: center;
  ${UserAvatar} {
    width: 24px;
    height: 24px;
    margin-right: 16px;
  }
`;
