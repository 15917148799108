import React from 'react';

import { useFormField } from 'libs/forms';

import { SwitchButtons } from '../molecules/switch-buttons/switch-buttons';

interface Props {
  name: string;
  placeholder?: string;
}

export function SwitchButtonsField(props: Props) {
  const { input, shouldShowError } = useFormField<string>(props.name);
  return (
    <SwitchButtons
      {...input}
      options={props.options}
      placeholder={props.placeholder}
      isInvalid={shouldShowError}
    />
  );
}
