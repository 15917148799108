import React from 'react';
import { useTheme, ThemeProvider } from 'styled-components';
import { useMemo } from 'react';

interface FormTheme {
  children: React.ReactNode;
  middle?: boolean;
}

export function FormThemeProvider(props: FormTheme) {
  const currentTheme = useTheme();

  const formTheme = useMemo(
    () => ({
      ...currentTheme,
      form: {
        middle: props.middle,
      },
    }),
    [currentTheme, props.middle],
  );

  return <ThemeProvider theme={formTheme}>{props.children}</ThemeProvider>;
}
