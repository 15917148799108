import React, { useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { InputProps } from 'libs/forms';

import { useDebouncedCallback } from 'use-debounce';

import { useOutsideClick } from 'libs/outsideClick';

import { Input } from '../../atoms/input/input';

type Props = InputProps<string> & {
  value: string;
  placeholder?: string;
  getSuggestions(value: string): string[];
  isInvalid?: boolean;
};

export function AutocompleteInput(props: Props) {
  const [activeSuggestion, setActiveSuggestion] = useState(0);
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [userInput, setUserInput] = useState<string>(props.value);
  const ref = useRef<HTMLDivElement>();
  useOutsideClick(ref, () => setSuggestions([]));

  const [getSuggestions] = useDebouncedCallback(async value => {
    const suggestions = await props.getSuggestions(value);
    setSuggestions(suggestions);
  }, 500);

  async function handleChange(e: React.FormEvent<HTMLInputElement>) {
    const value = (e.currentTarget as HTMLInputElement).value;
    props.onChange(value);
    setUserInput(value);
    setSuggestions([]);
    setActiveSuggestion(0);
    if (value.trim()) {
      await getSuggestions(value);
    }
  }

  function handleOnSuggestionClick(e: React.MouseEvent) {
    const target = e.currentTarget as HTMLElement;
    const value = target.innerText;
    setUserInput(value);
    props.onChange(value);
    setActiveSuggestion(0);
    setSuggestions([]);
  }

  function handleKeyDown(e: React.KeyboardEvent): void {
    if (suggestions.length > 0) {
      switch (e.key) {
        case 'Enter':
          if (suggestions) {
            const value = suggestions[activeSuggestion];
            setUserInput(value);
            props.onChange(value);
            setActiveSuggestion(0);
            setSuggestions([]);
          }
          break;
        case 'ArrowUp':
          if (activeSuggestion === 0) {
            return;
          }
          setActiveSuggestion(activeSuggestion - 1);
          scroll(activeSuggestion - 1);
          break;
        case 'ArrowDown':
          if (activeSuggestion + 1 === suggestions.length) {
            return;
          }
          setActiveSuggestion(activeSuggestion + 1);
          scroll(activeSuggestion + 1);
          break;
        case 'Escape':
          setActiveSuggestion(0);
          setSuggestions([]);
      }
    }
  }

  function scroll(index: number) {
    refs[index].current.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    });
  }

  function handleMouseOver(index: number) {
    setActiveSuggestion(index);
  }

  const refs = suggestions?.reduce((acc, _, index) => {
    acc[index] = React.createRef();
    return acc;
  }, {});

  return (
    <Root ref={ref as any}>
      <Input
        value={userInput}
        placeholder={props.placeholder}
        isInvalid={props.isInvalid}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      />
      {suggestions.length > 0 && (
        <SuggestionsList>
          {suggestions.map((suggestion, index) => (
            <Suggestion
              key={suggestion}
              ref={refs[index]}
              onClick={handleOnSuggestionClick}
              isActive={index === activeSuggestion}
              onMouseOver={() => handleMouseOver(index)}
            >
              {suggestion}
            </Suggestion>
          ))}
        </SuggestionsList>
      )}
    </Root>
  );
}

const Suggestion = styled.div<{ isActive: boolean }>`
  font-size: 14px;
  cursor: pointer;
  height: 29px;
  padding: 4px 16px;
  word-break: keep-all;

  ${props =>
    props.isActive &&
    css`
      background: #dfe8f6;
      color: #344ca4;
    `}
`;

const SuggestionsList = styled.div`
  background: #ffffff;
  box-shadow: 0 5px 25px rgba(104, 130, 159, 0.25);
  border-radius: 8px;
  padding: 8px 0;
`;

const Root = styled.div`
  position: relative;

  ${SuggestionsList} {
    position: absolute;
    min-width: 100%;
    max-height: 200px;
    overflow: scroll;
    display: grid;
    grid-gap: 8px;
    left: 0;
    top: calc(100% + 7px);
    z-index: 1;
  }
`;
