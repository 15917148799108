import React from 'react';

import { useFormField } from 'libs/forms';

import { TimeStartPicker } from '../molecules/time-start-picker/time-start-picker';

interface Props {
  name: string;
  placeholder?: string;
}

export function TimeStartPickerField(props: Props) {
  const { input, shouldShowError } = useFormField<string>(props.name);
  return (
    <TimeStartPicker
      {...input}
      placeholder={props.placeholder}
      isInvalid={shouldShowError}
    />
  );
}
