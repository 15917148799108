import React from 'react';

import { useFormField } from 'libs/forms';

import { CheckBox, CheckBoxProps } from '../atoms/checkbox/checkbox';

type Props = CheckBoxProps & {
  name: string;
};

export function CheckBoxField(props: Props) {
  const { input } = useFormField(props.name);
  return <CheckBox {...input} children={props.children} />;
}
