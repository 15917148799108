import React from 'react';

import { useFormField } from 'libs/forms';

import { RadioButton } from '../atoms/radio-button/radio-button';

export function RadioButtonField(props: {
  name: string;
  value: string;
  children: React.ReactNode;
}) {
  const { input } = useFormField(props.name);

  return (
    <RadioButton
      name={input.name}
      disabled={input.disabled}
      checked={input.value === props.value}
      onChange={() => input.onChange(props.value)}
    >
      {props.children}
    </RadioButton>
  );
}
