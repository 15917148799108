import React, { useMemo, useRef } from 'react';
import styled, { css } from 'styled-components';
import dayjs from 'dayjs';
import * as Yup from 'yup';

import { Form, InputProps, useFormField } from 'libs/forms';
import { useOpenableState } from 'libs/use-openable-state';
import { useOutsideClick } from 'libs/outsideClick';

import { FormNestedContext } from 'libs/forms/nestedForm';

import { FormLine, InputWrapper } from '../..';
import { focusCss, Placeholder } from '../../atoms/input-wrapper';
import { ReactComponent as TimeIcon } from './time-icon.svg';
import { Popup } from '../../../dropdown/atoms/popup';
import { TimePicker } from '../time-picker/time-picker';

function getTime(str) {
  const fakeDate = `2018-08-08`;
  return dayjs(`${fakeDate} ${str}`, 'YYYY-SS-MM HH:mm');
}

function getTimeString(str) {
  const fakeDate = `2018-08-08`;
  return dayjs(`${fakeDate} ${str}`, 'YYYY-SS-MM HH:mm').format('hh:mm a');
}

const schema = Yup.object({
  start: Yup.string()
    .required(' ')
    .test('validTime', 'invalid format ', value => {
      return getTimeString(value) !== 'Invalid Date';
    }),
  end: Yup.string()
    .required(' ')
    .test('valid, time', 'Invalid Date', function (value) {
      return dayjs(getTime(this.parent.end)).isAfter(
        getTime(this.parent.start),
      );
    }),
});

export function TimeRangePicker(props: InputProps<{ start: {}; end: {} }>) {
  const value = props.value ?? {
    start: '',
    end: '',
  };
  const dropdown = useOpenableState(false);
  const ref = useRef<HTMLDivElement>();
  useOutsideClick(ref, dropdown.close);

  function handleApplyClick(values) {
    dropdown.close();
    props.onChange(values);
  }

  const valueString =
    value.start &&
    value.end &&
    `${getTimeString(value.start)} - ${getTimeString(value.end)}`;

  const initialValues = useMemo(() => {
    return {
      start: value.start || '12:00',
      end: value.end || '13:00',
    };
  }, []);

  return (
    <Form
      schema={schema}
      onSubmit={handleApplyClick}
      initialValues={initialValues}
      render={({ handleSubmit }) => (
        <FormNestedContext.Provider value={undefined}>
          <TimeRangePickerRoot ref={ref as any}>
            <TimeRangePickerInput
              isOpened={dropdown.isOpened}
              onClick={dropdown.toggle}
              isInvalid={props.isInvalid}
            >
              {valueString ? (
                valueString
              ) : (
                <Placeholder>Start Time – End Time</Placeholder>
              )}
              <Icon />
            </TimeRangePickerInput>
            {dropdown.isOpened && (
              <DropDownPopup>
                <Times>
                  <FormLine label="Start Time">
                    <TimePickerField name="start" />
                  </FormLine>
                  <FormLine label="End Time">
                    <TimePickerField name="end" />
                  </FormLine>
                </Times>
                <Footer>
                  <ApplyButton onClick={handleSubmit}>Apply</ApplyButton>
                </Footer>
              </DropDownPopup>
            )}
          </TimeRangePickerRoot>
        </FormNestedContext.Provider>
      )}
    />
  );
}

function TimePickerField(props: { name: string }) {
  const { input, shouldShowError } = useFormField<string>(props.name);
  return <TimePicker {...input} isInvalid={shouldShowError} />;
}

const Icon = styled(TimeIcon)``;

const Times = styled.div`
  display: grid;
  grid-gap: 16px;
  padding: 16px;
`;

const ApplyButton = styled.div`
  font-size: 14px;
  line-height: 21px;
  cursor: pointer;
  color: var(--baseLinkColor);
`;

const Footer = styled.div`
  border-top: 1px solid #dfe8f6;
  padding-top: 8px;
  display: flex;
  justify-content: center;
`;

const DropDownPopup = styled(Popup)`
  width: 206px;
`;

const TimeRangePickerInput = styled(InputWrapper)<{ isOpened: boolean }>`
  --color: #a1b3ce;
  cursor: pointer;
  position: relative;

  ${Icon} {
    position: absolute;
    right: 12px;
  }

  ${props =>
    props.isOpened &&
    css`
      --color: #3c5bcc;
      ${focusCss}
    `}
`;

const TimeRangePickerRoot = styled.div`
  position: relative;

  ${DropDownPopup} {
    position: absolute;
    left: 0;
    top: calc(100% + 7px);
    z-index: 1;
  }
`;
