import React from 'react';

import { useFormField } from 'libs/forms';

import { ProfileSelector } from '../molecules/profile-selector/profile-selector';

interface Props {
  name: string;
  placeholder?: string;
  options: Array<{ label: string; value: unknown }>;
  AvatarComponent: React.ReactNode;
}

export function ProfileSelectorField(props: Props) {
  const { input, shouldShowError } = useFormField<number>(props.name);

  return (
    <ProfileSelector
      {...input}
      AvatarComponent={props.AvatarComponent}
      options={props.options}
      placeholder={props.placeholder}
      isInvalid={shouldShowError}
    />
  );
}
