import React from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';

import { ReactTimePicker } from 'libs/react-time-picker';
import { InputWrapper } from 'ui/ecosystems/forms/atoms/input-wrapper';

import { SwitchButtons } from '../switch-buttons/switch-buttons';

interface Props {
  value: string;
  onChange(value: string): void;
  isInvalid?: boolean;
}

const options = [
  {
    value: 'am',
    label: 'AM',
  },
  {
    value: 'pm',
    label: 'PM',
  },
];

export function TimePicker(props: Props) {
  const date = dayjs(`2020-01-01 ${props.value}`);

  const [time, a] = [date.format('hh:mm'), date.format('a')];

  function handlePeriodChange(value) {
    const newDate = dayjs(`2020/01/01 ${time} ${value}`);
    props.onChange(newDate.format('HH:mm'));
  }

  function handleTimeChange(value) {
    const newDate = dayjs(`2020/01/01 ${value} ${a}`);
    props.onChange(newDate.format('HH:mm'));
  }

  return (
    <React.Fragment>
      <TimePickerRoot>
        <TimeInput
          isInvalid={props.isInvalid}
          value={time}
          onChange={handleTimeChange}
        />
        <SwitchButtons
          options={options}
          value={a}
          onChange={handlePeriodChange}
        />
      </TimePickerRoot>
    </React.Fragment>
  );
}

const TimeInput = styled(InputWrapper).attrs({
  as: ReactTimePicker,
})`
  justify-content: center;
  padding: 0;
`;

const TimePickerRoot = styled.div`
  display: inline-grid;
  grid-template-columns: 72px auto;
  grid-gap: 8px;
`;
