import React from 'react';

import { useFormField } from 'libs/forms';

import { AutocompleteInput } from '../molecules/autocomplete-input/autocomplete-input';

interface Props {
  name: string;
  placeholder?: string;
  getSuggestions(value: string): string[];
}

export function AutocompleteInputField(props: Props) {
  const { input, shouldShowError } = useFormField<string>(props.name);

  return (
    <AutocompleteInput
      {...input}
      getSuggestions={props.getSuggestions}
      placeholder={props.placeholder}
      isInvalid={shouldShowError}
    />
  );
}
