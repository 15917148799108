import React from 'react';
import styled from 'styled-components';

export type RadioButtonProps = {
  children?: React.ReactNode;
  name: string;
  className?: string;
  onChange?: () => void;
  checked?: boolean;
  disabled?: boolean;
};

export function RadioButton(props: RadioButtonProps) {
  return (
    <RadioButtonRoot className={props.className}>
      <input
        type="radio"
        name={props.name}
        disabled={props.disabled}
        checked={props.checked}
        onChange={props.onChange}
      />
      <Label>{props.children}</Label>
    </RadioButtonRoot>
  );
}

const Label = styled.div`
  font-size: 14px;
  line-height: 21px;
  color: #172549;
  display: flex;
  align-items: center;

  :before {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    border: 2px solid var(--baseInputBorderColor);
    margin-right: 4px;
    border-radius: 50%;
  }
`;

const RadioButtonRoot = styled.label`
  cursor: pointer;

  input[type='radio'] {
    display: block;
    width: 0;
    height: 0;
    opacity: 0;
    clip: rect(0, 0, 0, 0);
  }

  input[type='radio']:checked + ${Label}:before {
    border: 4px solid var(--darkBlue);
  }

  input:focus + ${Label}:before {
    border-color: var(--darkBlue);
  }

  :hover ${Label}:before {
    border-color: var(--darkBlue);
  }
`;
