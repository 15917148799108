import React from 'react';
import styled from 'styled-components';

import {
  ArrayFieldWrapper,
  useArrayFormField,
  useFormField,
  NestedForm,
} from 'libs/forms';

// @ts-ignore
import { ReactComponent as CloseIcon } from 'ui/atoms/icons/close.svg';

import { FieldsGroup } from '../..';
import { DaysMultiSelect } from '../../molecules/days-multi-select/days-multi-select';
import { TimeRangePicker } from '../../molecules/time-range-picker/time-range-picker';
import { TwoFields } from '../..';

export function ProviderScheduleField(props: { name: string }) {
  const { items, addItem } = useArrayFormField<{}>(props.name);

  if (items.length === 0) {
    return (
      <EmptyList>
        <AddItem onClick={() => addItem({})}>Add Days</AddItem> to create
        schedule
      </EmptyList>
    );
  }

  const selectedDays = items.reduce(
    (acc, item) => acc.concat(item.value.days),
    [],
  );

  return (
    <ArrayFieldWrapper>
      <FieldsGroup>
        {items.map(item => (
          <NestedForm name={item.name}>
            <Item>
              <DaysMultiSelectField name="days" skip={selectedDays} />
              <RemoveButton onClick={item.remove} />
              <TwoFields>
                <TimeRangePickerField name="time" />
                <TimeRangePickerField name="breakTime" />
              </TwoFields>
            </Item>
          </NestedForm>
        ))}
        {selectedDays.length < 7 && (
          <AddItem onClick={() => addItem({})}>Add Days</AddItem>
        )}
      </FieldsGroup>
    </ArrayFieldWrapper>
  );
}

function DaysMultiSelectField(props) {
  const { input } = useFormField(props.name);
  return (
    <DaysMultiSelect {...input} placeholder="Select Days" skip={props.skip} />
  );
}

function TimeRangePickerField(props) {
  const { input } = useFormField(props.name);
  return <TimeRangePicker {...input} />;
}

const AddItem = styled.span`
  color: var(--baseLinkColor);
  cursor: pointer;
  font-size: 14px;
  line-height: 21px;
`;

const EmptyList = styled.div`
  font-size: 14px;
  line-height: 21px;
`;

const Item = styled.div`
  display: grid;
  grid-template-columns: 1fr 16px;
  grid-gap: 8px;
  align-items: center;
`;

const RemoveButton = styled(CloseIcon)`
  width: 16px;
  height: 16px;
  cursor: pointer;
  .icon {
    fill: #a1b3ce;
  }
`;
