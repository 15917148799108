import React from 'react';
import styled from 'styled-components';

import { InputProps } from 'libs/forms';
import { DropdownItem, DropdownTemplate } from 'ui/ecosystems/dropdown';
import { WEEK_DAYS } from 'services/week-days';

import { CheckBox } from '../../atoms/checkbox/checkbox';
import { Placeholder } from '../../atoms/input-wrapper';

type Props = InputProps<number[]> & {
  placeholder?: string;
  skip: number[];
};

const labels = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

export function DaysMultiSelect(props: Props) {
  const value = props.value || [];
  const valuesHash = value.reduce(
    (acc, value) => ({
      ...acc,
      [value]: true,
    }),
    {},
  );

  function dayOn(day: number) {
    props.onChange([...value, day]);
  }

  function dayOf(day: number) {
    props.onChange(value.filter(d => d !== day));
  }

  const label = props.value.length === 0 ? '' : getLabel(props.value);

  return (
    <DropdownTemplate
      renderLabel={() =>
        label || <Placeholder>{props.placeholder}</Placeholder>
      }
      renderPopupContent={() =>
        WEEK_DAYS.map((day, index) => {
          if (props.skip.indexOf(index) !== -1 && !valuesHash[index]) {
            return null;
          }

          return (
            <DropdownItem
              key={day}
              as={CheckBoxItem}
              value={valuesHash[index]}
              onClick={() => (valuesHash[index] ? dayOf(index) : dayOn(index))}
            >
              {day}
            </DropdownItem>
          );
        })
      }
    />
  );
}

export function getLabel(values: number[]) {
  if (values.length === 1) {
    return WEEK_DAYS[values[0]];
  }

  if (values.length === 7) {
    return 'Every day';
  }

  const daysLabel = values
    .sort()
    .map(day => labels[day])
    .join(', ');

  if (daysLabel === 'Su, Sa') {
    return 'Weekends';
  }

  if (daysLabel === 'Mo, Tu, We, Th, Fr') {
    return 'Weekdays';
  }

  return daysLabel;
}

const CheckBoxItem = styled(CheckBox)`
  display: block;
`;
