import React, { useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import dayjs from 'dayjs';

import { useOpenableState } from 'libs/use-openable-state';
import { useOutsideClick } from 'libs/outsideClick';
import { InputProps } from 'libs/forms';

// @ts-ignore
import { ReactComponent as TimeIcon } from 'ui/atoms/icons/time-icon.svg';

import { mediaPhone } from 'ui/styles/media';

import { InputWrapper } from '../..';
import { focusCss, Placeholder } from '../../atoms/input-wrapper';
// @ts-ignore
import { Popup } from '../../../dropdown/atoms/popup';
import { TimePicker } from '../time-picker/time-picker';

function getTimeString(str: string) {
  const fakeDate = `2018-08-08`;
  return dayjs(`${fakeDate} ${str}`, 'YYYY-SS-MM HH:mm').format('hh:mm a');
}

type Props = InputProps<string> & {
  value: string;
  onChange(value: string): void;
};

export function TimeStartPicker(props: Props) {
  const defaultTime = '11:00';
  const [valuePicker, onChangePicker] = useState(
    props.value ? props.value : defaultTime,
  );
  const [value, valueOnChange] = useState(props.value ?? '');

  const dropdown = useOpenableState(false);
  const ref = useRef<HTMLDivElement>();
  useOutsideClick(ref, dropdown.close);

  function handleApplyClick() {
    dropdown.close();
    valueOnChange(valuePicker);
    props.onChange(valuePicker);
  }

  const valueString = value && `${getTimeString(value)}`;

  return (
    <TimeStartPickerRoot ref={ref as any}>
      <TimeStartPickerInput
        isOpened={dropdown.isOpened}
        onClick={dropdown.toggle}
        isInvalid={props.isInvalid}
      >
        {valueString ? valueString : <Placeholder>Time</Placeholder>}
        <Icon />
      </TimeStartPickerInput>
      {dropdown.isOpened && (
        <DropDownPopup>
          <Time>
            <TimePicker value={valuePicker} onChange={onChangePicker} />
          </Time>
          <Footer>
            <ApplyButton onClick={handleApplyClick}>Apply</ApplyButton>
          </Footer>
        </DropDownPopup>
      )}
    </TimeStartPickerRoot>
  );
}

const Icon = styled(TimeIcon)``;

const Time = styled.div`
  padding: 16px;
`;

const ApplyButton = styled.div`
  font-size: 14px;
  line-height: 21px;
  cursor: pointer;
  color: var(--baseLinkColor);
`;

const Footer = styled.div`
  border-top: 1px solid #dfe8f6;
  padding-top: 8px;
  display: flex;
  justify-content: center;
`;

const DropDownPopup = styled(Popup)`
  width: 206px;
`;

const TimeStartPickerInput = styled(InputWrapper)<{ isOpened: boolean }>`
  --color: #a1b3ce;
  cursor: pointer;
  position: relative;

  ${Icon} {
    position: absolute;
    right: 12px;
  }

  ${props =>
    props.isOpened &&
    css`
      --color: #3c5bcc;
      ${focusCss}
    `}
`;

const TimeStartPickerRoot = styled.div`
  position: relative;

  ${DropDownPopup} {
    position: absolute;
    left: 0;
    top: calc(100% + 7px);
    z-index: 1;

    ${mediaPhone} {
      left: auto;
      right: 0;
    }
  }
`;
